import React, { useRef } from 'react'
import styled from '@emotion/styled'

const Drawing = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: visible;

	.curve {
		fill: none;
		stroke: none;
	}
	
	text {
		fill: #231e1f;
		font-size: 30px;
	}
`

export default function TextPathDrawing(props) {
	let curve = useRef();
	let curve_textpath = useRef();
	
	let start = Date.now();
	let points = [];
	let strokes = [];
	let prev = null;
	let stroke = {};

	const startPath = () => {
		start = Date.now();
		
		stroke = {
			elapsed: 0,
			time: start, 
			dist: 0,
			x: 0, 
			y: 0
		};  

		strokes = [stroke];
		prev = stroke;
	}
	startPath();

	const onMouseMove = (e) => {
		let time = start;
		let elapsed = time - prev.time;  
		
		let dist = getDistance(prev, {x: e.clientX, y: e.clientY});

		let x = parseInt(e.clientX);
		let y = parseInt(e.clientY);

		stroke = {
			elapsed: elapsed,
			time: time,
			dist: prev.dist + dist,
			x: x,
			y: y
		};
		
		prev = stroke;
		strokes.push(stroke);  
		points.push(x, y);
		
		curve.current.setAttribute("d", solve(points, 0));

		if (points.length > 200 && curve.current.getTotalLength() > 3000) {
			points.splice(0, 6);
		}
	}

	const getDistance = (p1, p2) => {
		var dx = p2.x - p1.x;
		var dy = p2.y - p1.y;
		return Math.sqrt(dx * dx + dy * dy);
	}

	const solve = (data, k) => {
		if (k == null) k = 1;
		
		var size = data.length;
		var last = size - 4;

		var path = `M${data[0]},${data[1]}`;

		for (var i = 0; i < size - 2; i +=2) {

			var x0 = i ? data[i - 2] : data[0];
			var y0 = i ? data[i - 1] : data[1];

			var x1 = data[i + 0];
			var y1 = data[i + 1];

			var x2 = data[i + 2];
			var y2 = data[i + 3];

			var x3 = i !== last ? data[i + 4] : x2;
			var y3 = i !== last ? data[i + 5] : y2;

			var cp1x = parseInt(x1 + (x2 - x0) / 6 * k);
			var cp1y = parseInt(y1 + (y2 - y0) / 6 * k);

			var cp2x = parseInt(x2 - (x3 - x1) / 6 * k);
			var cp2y = parseInt(y2 - (y3 - y1) / 6 * k);

			path += ` C${cp1x},${cp1y},${cp2x},${cp2y},${x2},${y2}`;

		} 

		return path;
	}

  return (
    <Drawing
			id="textpath_drawing"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			onPointerMove={onMouseMove}
			className="hidden lg:block"	
    >
			<path ref={curve} id="curve" className="curve" strokeMiterlimit="10" />
			<text>
				<textPath
					ref={curve_textpath}
					id="curve_textpath"
					xlinkHref="#curve"
					startOffset="100%"
					className="uppercase"
					style={{textAnchor: "end", fontSize: "20px"}}
				>
					Wild Places is a brand identity company and design studio with a focus on creating bold, adventurous identities
				</textPath>
			</text>
    </Drawing>
  )
}
