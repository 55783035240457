import React, { useState } from "react"
import { useSpring, animated, config } from 'react-spring'


export default function TeamBlockButton(props) {
  const openElement = props.openElement
  const setOpenState = props.setOpenState
  const [currentButton, setCurrentButton] = useState(null)

  const rotateButton = useSpring({
    to: [{
      transform: openElement && currentButton === openElement ? "rotate(45deg)" : "rotate(0deg)",
    }],
    config: config.stiff,
  });

  return (
    <animated.button
      type="button"
      style={rotateButton}
      className="mr-2 text-3xl"
      onClick={(e) => {
        openElement && e.currentTarget.parentElement === openElement ?
          setOpenState(null) : setOpenState(e.currentTarget.parentElement)
        openElement && e.currentTarget.parentElement === openElement ?
          setCurrentButton(null) : setCurrentButton(e.currentTarget.parentElement)
      }}
    >+</animated.button>
  )
}
