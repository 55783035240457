import React, { useState, useRef, useEffect } from "react"
import { css } from "@emotion/core"
import { useSpring, animated, config } from 'react-spring'


export default function TeamBlock(props) {
  const [active, setActive] = useState(false)
  const container = useRef()
  
  const toggleDisplay = useSpring({
    to: [{display: active ? "flex" : "none"}],
    from: {display: "none"},
    delay: active ? 0 : 100
  });
  const flipOpen = useSpring({
    to: [{
      transform: active ? "rotateX(0)" : "rotateX(180deg)",
    }],
    from: {transform: "rotateX(180deg)"},
    config: config.slow,
  });
  const openDrawer = useSpring({
    to: [{
      maxHeight: active ? "1050px" : "0px",
    }],
    from: {maxHeight: "0px"},
    config: config.gentle,
  });

  const showImage = useSpring({
    to: [
      {
        visibility: active ? "visible" : "hidden",
        opacity: active ? 1 : 0,
        transform: active ? "translateX(0px)" : "translateX(20px)"
      }
    ],
    from: {visibility: "hidden", opacity: 0, transform: "translateX(20px)"},
    config: config.gentle,
    delay: !props.isMobile ? 400 : 150,
  });
  const showText = useSpring({
    to: [
      {
        visibility: active ? "visible" : "hidden",
        opacity: active ? 1 : 0,
        transform: active ? "translateX(0px)" : "translateX(-20px)"
      }
    ],
    from: {visibility: "hidden", opacity: 0, transform: "translateX(-20px)"},
    config: config.gentle,
    delay: !props.isMobile ? 500 : 250,
  });


  useEffect(() => {
    if (props.openElement && container.current.parentElement === props.openElement) {
      
      setActive(true)
    } else {
      setActive(false)
    }
  }, [props.openElement])

  return (
    <animated.div
      style={toggleDisplay}
      ref={container}
      className="flex flex-col lg:justify-center lg:items-center lg:w-full lg:h-full lg:fixed top-0 left-0 z-2"
    >
      <animated.div
        style={!props.isMobile ? flipOpen : openDrawer}
        className="flex flex-col lg:flex-row bg-white p-4 my-4 lg:mt-0 lg:p-8"
        css={css`
          border: 3px solid black;
          
          @media screen and (min-width: 1024px) {
            width: 680px;
          }
        `
      }>
        <animated.img 
          // style={!props.isMobile ? showImage : null}
          style={showImage}
          className="w-full object-cover mb-4 lg:order-1"
          src={props.content.image}
          alt={props.content.title}
          css={css`
            max-height: 450px;

            @media screen and (min-width: 1024px) {
              max-height: 400px;
            }
          `
        }/>
        <animated.div
          // style={!props.isMobile ? showText : null} 
          style={showText} 
          className="lg:order-none lg:pr-4"
        >
          <h3 className="font-bold mb-2 text-xl">{props.content.title}</h3>
          <p className="mb-6 text-lg">{props.content.bio}</p>
          <a href="mailto:andrew@weareoust.co" className="uppercase underline text-xl">Contact</a>
        </animated.div>
      </animated.div>
    </animated.div>
  )
}
