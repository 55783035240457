import Andrew from "../images/Andrew.jpg"
import Matt from "../images/Matt.jpg"
import Ashley from "../images/Ashley.jpg"
import Adan from "../images/Adan.jpg"
import Victoria from "../images/Victoria.jpg"

export {
  Andrew,
  Matt,
  Ashley,
  Adan,
  Victoria
}

export default {
  Andrew,
  Matt,
  Ashley,
  Adan,
  Victoria
}